<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Créditos - Listar</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
         

                <b-col sm="12" md="8">
          
                </b-col>

                
                <b-col sm="6" md="3">
                  <b-form-group label=".">
                    <b-input-group>
                    <b-form-input v-model="search" class="form-control"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="ListCredit"><b-icon icon="search"></b-icon></b-button>
                    </b-input-group-append>
                  </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col sm="6" md="1">
                  <b-form-group label=".">
                    <b-link  v-if="Permission('CreditAdd')" class="btn form-control btn-primary"  :to="{ path: '/credito/nuevo' }" append ><i class="fas fa-file"></i></b-link>
                  </b-form-group>
                </b-col>
            </b-row>

            <div class="table-responsive mt-3 height-table-lg">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="7%" class="text-center">F. Solicitud</th>
                    <th width="7%" class="text-center">Código</th>
                    <th width="35%" class="text-center">Cliente</th>
                    <th width="7%" class="text-center">Crédito</th>
                    <th width="7%" class="text-center">TEA</th>
                    <th width="8%" class="text-center">Monto</th>
                    <th width="5%" class="text-center">Cuotas</th>
                    <th width="10%" class="text-center">Usuario</th>
                    <th width="8%" class="text-center">Estado</th>
                    <th width="7%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr :class=" item.credit_plus == 1 ? 'bg-info':''">
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.application_date  }}</td>
                    <td class="text-center"> {{ item.code  }}</td>
                    <td class="text-left"> {{ item.client_name  }}</td>
                    <td class="text-left"> {{ item.product_name  }}</td>
                    <td class="text-right"> {{ item.financial_data_tea  }}%</td>
                    <td class="text-right"> {{ item.amount  }}</td>
                    <td class="text-right"> {{ item.dues  }}</td>
                    <td class="text-left"> {{ item.user  }}</td>
                    <td class="text-center">
                        <b-badge v-if="item.credit_status == 1" variant="warning">Pendiente</b-badge>
                        <b-badge v-if="item.credit_status == 2" variant="info">Aprobado</b-badge>
                        <b-badge v-if="item.credit_status == 3" variant="danger">Rechazado</b-badge>  
                        <b-badge v-if="item.credit_status == 4" variant="success">Pagado</b-badge>
                        
                    </td>
                    <td class="text-center" >
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item v-if="Permission('CreditEdit') && item.credit_status == 1" @click="EditCredit(item.id_credit)" >Editar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('CreditView')" @click="ViewCredit(item.id_credit)" >Ver</b-dropdown-item>
                        <b-dropdown-item  v-if="Permission('CreditDelete') && item.credit_status == 1" @click="ConfirmDeleteCredit(item.id_credit)" >Eliminar</b-dropdown-item>
                        <b-dropdown-item @click="OpenCreditApplication(item.id_credit)" >PDF Solicitud de Crédito</b-dropdown-item>
                        <b-dropdown-item @click="OpenExportLoanApplicationWord(item.id_credit)" >WORD Solicitud de Crédito</b-dropdown-item>
                        
                        <b-dropdown-item @click="OpenCreditContract(item.id_credit)" >PDF Contrato de Crédito</b-dropdown-item>

                        <b-dropdown-item @click="OpenCreditContractGuarantee(item.id_credit)" >PDF Contrato de Garantía</b-dropdown-item>
                        <b-dropdown-item @click="OpenCreditContractNote(item.id_credit)" >WORD Pagaré</b-dropdown-item>
                        <b-dropdown-item @click="OpenCreditExpenseReceipt(item.id_credit)" >PDF Recibo de Egreso</b-dropdown-item>
                        
                        <!-- <b-dropdown-item @click="OpenCreditSalesReceipt(item.id_credit)" >PDF Boleta de venta</b-dropdown-item> -->

                        <b-dropdown-item @click="OpenFormatCreditMype(item.id_credit)" >Formato de Crédito MYPE</b-dropdown-item>
                        <b-dropdown-item @click="OpenFormatCreditAgricultural(item.id_credit)" >Formato de Crédito Agrícolas</b-dropdown-item>
                        
                        
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage" v-on:input="ListCredit" :total-rows="rows" :per-page="perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>

</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
export default {
  name: "BranchOfficeList",
  components:{
    vSelect,
  },
  data() {
    return {
      module:'Credit',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      data_table: [],
      search: "",
    };
  },
  mounted() {
    this.ListCredit();
  },
  methods: {
    ListCredit,
    EditCredit,
    ViewCredit,
    ConfirmDeleteCredit,
    DeleteCredit,
    Permission,

    OpenCreditApplication,
    OpenCreditContract,

    OpenCreditContractGuarantee,
    OpenCreditContractNote,
    OpenCreditExpenseReceipt,
    
    OpenCreditSalesReceipt,

    OpenFormatCreditAgricultural,
    OpenFormatCreditMype,

    OpenExportLoanApplicationWord,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    }
  },
};

function ListCredit() {

  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "credit/list/"+this.user.id_branch_office+"/"+ search + "?page=" + this.currentPage;

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// Editar usuario
function EditCredit(id_credit) {
  this.$router.push({
    name: "CreditEdit",
    params: { id_credit: je.encrypt(id_credit) },
  });
}

// Ver Usuario
function ViewCredit(id_credit) {
  this.$router.push({
    name: "CreditView",
    params: { id_credit: je.encrypt(id_credit) },
  });
}

// Confirmar eliminar
function ConfirmDeleteCredit(id_credit) {
  Swal.fire({
    title: "Esta seguro de eliminar el crédito?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteCredit(id_credit);
    }
  });
}

// eliminar usuario
function DeleteCredit(id_credit) {
  let me = this;
  let url = this.url_base + "credit/delete/" + id_credit;
  axios({
    method: "delete",
    url: url,
    headers: {token: this.token, module: this.module,role: 4,},
  })
    .then(function (response) {
       if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_credit).indexOf(response.data.result.id_credit);
        me.data_table.splice(index, 1);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function OpenCreditApplication(id_credit) {
  let me = this;
  let url = me.url_base + "credit-application-pdf/"+id_credit;
  window.open(url,'_blank');
}

function OpenCreditContract(id_credit) {
  let me = this;
  let url = me.url_base + "credit-contract-pdf/"+id_credit+"/"+this.user.id_user;
  window.open(url,'_blank');
}

function OpenCreditContractGuarantee(id_credit) {
  let me = this;
  let url = me.url_base + "credit-contract-guarantee-pdf/"+id_credit+"/"+this.user.id_user;
  window.open(url,'_blank');
}

function OpenCreditContractNote(id_credit) {
  let me = this;
  let url = me.url_base + "export-format-contract-note";
  window.open(url,'_blank');
}

function OpenCreditExpenseReceipt(id_credit) {
  let me = this;
  let url = me.url_base + "credit-expense-receipt-pdf/"+id_credit+"/"+this.user.id_user;
  window.open(url,'_blank');
}



// function OpenCreditRefinancingAgreement(id_credit) {
//   let me = this;
//   let url = me.url_base + "credit-refinancing-agreement-pdf/"+id_credit+"/"+this.user.id_user;
//   window.open(url,'_blank');
// }

function OpenCreditSalesReceipt(id_credit) {
  let me = this;
  let url = me.url_base + "credit-sales-receipt-pdf/"+id_credit+"/"+this.user.id_user;
  window.open(url,'_blank');
}

function OpenFormatCreditAgricultural() {
  let me = this;
  let url = me.url_base + "export-format-credit/2";
  window.open(url,'_blank');
}

function OpenFormatCreditMype() {
  let me = this;
  let url = me.url_base + "export-format-credit/1";
  window.open(url,'_blank');
}


function OpenExportLoanApplicationWord() {
  let me = this;
  let url = me.url_base + "export-word-loan-aplication";
  window.open(url,'_blank');
}


// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>